@switch (mode) {
  @case ('overlay') {
    <app-overlay appFloatingElement (close)="onClose(false)">
      <div class="overlay__buttons">
        <app-button theme="transparent" class="back-button" (click)="onClose(false)">
          {{ 'action.back' | translate }}
        </app-button>

        @if (
          (paymentType === PaymentTypes.RegularPayment ||
            paymentType === PaymentTypes.EventPayment ||
            (paymentType === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
            (paymentType === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
          (paidStatus === PaidStatus.NotPaid || paidStatus === PaidStatus.Optional)
        ) {
          <app-button theme="dark" class="next-button" (click)="onClose(true)">
            {{ 'economy.generate_new_invoice' | translate }}
          </app-button>
        }
      </div>

      <div class="overlay__header">
        <h2>{{ 'economy.invoice_history_for' | translate }} {{ userName }}</h2>
        <p class="sub-header ns-text small">{{ paymentName }}</p>
      </div>

      <div class="overlay__body">
        <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
      </div>
    </app-overlay>
  }

  @case ('compact') {
    <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
  }
}

<ng-template #tableTemplate>
  <div class="content">
    @if (table.loading && !table.data?.length) {
      <app-loader [size]="50" [alignment]="{ top: '40px' }"></app-loader>
    }
  
    @if (table.data?.length) {
      <app-fade-in>
        <app-table
          [data]="table.data"
          [columns]="table.columns"
          [loading]="table.loading"
          [paginator]="false"
          [search]="false"
        >
          <ng-container [matColumnDef]="Columns.InvoiceNumber">
            <mat-header-cell *matHeaderCellDef>{{ 'table.invoice_number' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable">
              <button class="ns-clickable-cell-button" appBlurOnClick (click)="openInvoice(row)">
                <span>{{ row.invoice_number }}</span>
                <mat-icon>open_in_new</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
      
          <ng-container [matColumnDef]="Columns.GenerationDate">
            <mat-header-cell *matHeaderCellDef>{{ 'table.generation_date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <p class="date">{{ row.generated_at | date: DateFormat.shortDateTime }}</p>
            </mat-cell>
          </ng-container>
        </app-table>
      </app-fade-in>
    } @else if (!table.loading) {
      <div class="empty-state">
        <img src="/assets/img/empty-states/economy/invoice-history.svg">
        <h5>{{ 'payment_details.no_invoices_title' | translate }}</h5>
        <p class="ns-text">{{ 'payment_details.no_invoices_description' | translate }}</p>
      </div>
    }
  </div>
</ng-template>
